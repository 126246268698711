import * as React from 'react';
import './App.css';

import {RedocStandalone} from 'redoc';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <RedocStandalone specUrl="/openapi-embea.yaml" options={
                    {
                        theme: {
                            colors: {
                                primary: {
                                    main: '#002324'
                                },
                                http: {
                                    post: '#ACBA6A',
                                    get: '#6372C1',
                                    patch: '#168C77',
                                },
                                responses: {
                                    error: {
                                        color: '#F7945D',
                                        tabTextColor: '#F7945D',
                                        backgroundColor: 'rgba(247, 148, 93, 0.15)'
                                    },
                                    success: {
                                        color: '#1EBB9F',
                                        tabTextColor: '#1EBB9F',
                                        backgroundColor: 'rgba(30, 187, 159, 0.15)'
                                    }
                                }
                            },
                            schema: {
                                requireLabelColor: '#809596'
                            },
                            rightPanel: {
                                backgroundColor: '#002324'
                            },
                            logo: {
                                maxWidth: '50%',
                                gutter: '10px'
                            },
                            typography: {
                                headings: {
                                    fontFamily: 'Inter, sans-serif'
                                }
                            },
                            codeBlock: {
                                backgroundColor: '#001616'
                            },
                        },
                        hideSchemaTitles: 'true',
                        hideDownloadButton: 'true'
                    }}/>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                </a>
            </header>
        </div>
    );
}

export default App;
